
import { Actions, FETCH_STARTED, DATASET_FETCH_SUCCEEDED, CREATE_NEW_DATASET, DATASET_VIEW, SELECTED_DATASET } from './actions';
import State, { Data } from '../models/state';
import data from '../utils/data';

export default function reducer(state: State, action: Actions): State {

    if (action.type === FETCH_STARTED) {
        return {
            ...state,
            loading: true,
        };
    }

    if (action.type === DATASET_FETCH_SUCCEEDED) {
        // const apiData: Data = data.fetch_catalog_data();
        return {
            ...state,
            loading: false,
            apiData: action.data,
        };
    }

    if (action.type === DATASET_VIEW) {
        return {
            ...state,
            view: action.view
        }
    }

    if (action.type === SELECTED_DATASET) {
        return {
            ...state,
            selectedDataSet: action.selectedDataSet
        }
    }

    return { ...state };
}