import React, { FC, useEffect, useState, useCallback, useContext } from "react";
import { redirect} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTheme } from "@mui/material/styles";
import { ThemeProvider, createTheme, Container } from '@mui/system';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { autocompleteClasses, TextField, Tooltip, Typography } from "@mui/material";
import { Outlet, Link } from "react-router-dom";
import { NavigationType, UNSAFE_NavigationContext } from "react-router-dom";
import { History,} from "history";


import State, { Data, Gridded, Station, View } from '../models/state';
import data from '../utils/data';
import { datasetsFetchSucceeded, setSelectedDataSet, setView } from "../reducers/actions";
import ZarrLogo from "../icons/zarr-logo";
import InfoIcon from "../icons/info-icon";

const DataSets: FC<{}> = () => {
    // let screenSize = window.innerWidth;
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
      });
      const setDimension = () => {
        getDimension({
          dynamicWidth: window.innerWidth,
          dynamicHeight: window.innerHeight
        })
      }
    let isMobile = screenSize.dynamicWidth <= 600;
    let [descriptionWidth, setDescriptionWidth] = useState("100%")

    const dispatch = useDispatch();
    const state = useSelector((state: State) => {
        return {
            apiData: state.apiData,
            selectedDataSetType: state.view,
        };
    });
    
    const setStationDataSets = (view: View) => {
        dispatch(setView(view))
    }

    const selectDataSet = (datasetName: string) => {
        dispatch(setSelectedDataSet(datasetName));
    }

    const hasTags = (datasetName: string) => {
        const dataset = state.apiData?.datasets[state.selectedDataSetType][datasetName]
        return(dataset?.tags && dataset?.tags && dataset?.tags[0] && dataset?.tags[0] !== "" );
    }

    const sanitizedDescription = (datasetName: string) => {
        const maxLength = isMobile ? 105 : 255
        const dataset = state.apiData?.datasets[state.selectedDataSetType][datasetName]
        if (dataset?.description?.length && dataset?.description?.length > maxLength) {
            return dataset?.description?.slice(0,maxLength)+'...'
        } else {
            return dataset?.description ? dataset?.description : "No Description Available"
        }
    }
    const showableTags = (datasetName: string) => {
        if (isMobile) {
            return [state.apiData?.datasets[state.selectedDataSetType][datasetName].tags[0]]
        } else {
            return state.apiData?.datasets[state.selectedDataSetType][datasetName].tags.slice(
                0, 3)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        isMobile = window.innerWidth < 600;
        isMobile ? setDescriptionWidth(screenSize.dynamicWidth-85+"px") : setDescriptionWidth("100%");
    }, [window.innerWidth])

    return (
            <Box sx={{ display: 'flex', flexDirection: "column", mx: 'auto', maxWidth: {xs: "100%", lg: 1600}, mb: 15 }}>
                <Grid container direction="row" sx={{}}>
                    <Box sx={{display: 'flex', flexDirection: "row", background: 'white', borderRadius: 100, ml: 2, cursor: 'pointer'}}>
                        <Box 
                            sx={{display: 'flex', 
                                px: 3,
                                py: 1, 
                                background: state.selectedDataSetType === 'Station' ? '#D2F1FF' : 'white',
                                color: state.selectedDataSetType === 'Station' ? "#006DA0" : "#A8AFB8", 
                                borderRadius: 100}}
                                onClick={() => setStationDataSets(View.Station)}
                                >
                            Station
                        </Box>
                
                        <Box 
                            sx={{display: 'flex', 
                            px: 3,
                            py:1, 
                            background: state.selectedDataSetType === 'Gridded' ? '#D2FFD7' : 'white',
                            color: state.selectedDataSetType === 'Gridded' ? "#0DA000" : "#A8AFB8", 
                            borderRadius: 100}}
                            onClick={() => setStationDataSets(View.Gridded)}
                            >
                            Gridded
                        </Box>                
                    </Box>
                    <Tooltip 
                        title="A gridded dataset provides consistent climate variable readings across the 
                        entirety of a dataset’s temporal and spatial coverage area, while a station dataset 
                        provides more accurate inputs for locations measured but because they are not 
                        regular nor continuous- analysis in their “blind spots” may be low quality."
                        arrow
                        >
                        <Button sx={{ml: "-20px"}}>
                            <InfoIcon/>
                        </Button>
                    </Tooltip>
                </Grid> 
                    <Grid container direction="row">
                        {state.apiData?.datasets && Object.keys(state.apiData?.datasets[state.selectedDataSetType]).map((datasetName) =>
                        <Grid sm={12} md={6} item={true} key={datasetName} sx={{}}>
                                <Link to="/detail" style={{ textDecoration: 'none' }}>
                                    <Paper sx={{ display: 'flex', 
                                            flexDirection: 'column', 
                                            margin: "10px", 
                                            height: '165px',
                                            borderRadius: 2, 
                                            cursor: 'pointer', 
                                            boxShadow: '0px 10px 20px -4px rgba(136, 136, 136, 0.25)',
                                            p: 2,
                                        }}
                                        onClick={() => selectDataSet(datasetName)}>

                                    <Container sx={{height: "160px"}}>
                                        <Typography sx={{fontSize: {md: 19, lg: 25}, fontWeight: 550, textTransform: 'uppercase', color: "#54555B", height: 41 }}>
                                            {datasetName} 
                                            <Tooltip arrow title="Zarr is the storage backend dClimate uses for gridded climate data. 
                                            Zarr has a cloud-nati.tagsve file format which allows us to serve datasets quickly, improving their accessibility.">
                                                <Button sx={{p:0}}>
                                                    {state.apiData?.datasets[state.selectedDataSetType][datasetName].zarr && <ZarrLogo/>}
                                                </Button>
                                            </Tooltip>
                                        </Typography>
                                        <Box sx={{overflowY: "hidden", height: "80px", minWidth:  descriptionWidth, mb:1}}>
                                           <Typography sx={{mt:1, color: 'rgba(84, 85, 91, 0.7)', height: "70px", overflow: "wrap"}} >
                                              {sanitizedDescription(datasetName)} 
                                           </Typography> 
                                        </Box>
                                        
                                        <Box sx={{display: 'flex', flexDirection: "Row", float: 'bottom', whiteSpace: "nowrap"}}>
                                            <Typography sx={{ color: 'rgba(84, 85, 91, 0.7)', display: 'flex', fledDirection: 'row', mt:1, mr: 2}}>
                                                {state.apiData?.datasets[state.selectedDataSetType][datasetName].category}
                                            </Typography>
                                            <Grid container justifyContent='flex-end' sx={{
                                                display: isMobile ? 'flex' : 'inline-flex',
                                                flexDirection: "column", 
                                                height: 50,
                                                overflowX: isMobile ? "wrap" : "hidden",
                                                overflowScrolling: "touch",
                                                WebkitOverflowScrolling: "auto",
                                                justifyContent: 'flex-end'
                                            }}>
                                                <Box className="tags-box" sx={{display: 'flex', 
                                                           flexDirection: "row", 
                                                           height: 50,
                                                           width: "auto",
                                                           justifyContent: "flex-end"
                                                        }}>
                                                {hasTags(datasetName) &&
                                                    showableTags(datasetName)?.map((tag)=>
                                                    <Box key={tag} sx={{display: "flex",
                                                    flexDirection: "row",
                                                    borderRadius: 100, 
                                                    border: state.selectedDataSetType === 'Station' ? '2px solid #30A1D6' : '2px solid #0DA000',
                                                    background: state.selectedDataSetType === 'Station' ? '##F0FAFF' : '#D2FFD7',
                                                    height: 20,
                                                    mx:isMobile ? 0 : "4px",
                                                    mt: 1,
                                                    textAlign: 'center'
                                                }}>
                                                        <Typography sx={{mx: 2,
                                                                        fontSize: "0.8rem",
                                                                        color: state.selectedDataSetType === 'Station' ? '#30A1D6' : '#0DA000' 
                                                                    }}>
                                                                {tag}
                                                        </Typography>
                                                    </Box>
                                                )} 
                                                </Box>

                                            </Grid>
                                        </Box>    
                                    </Container>
                                </Paper>
                            </Link>
                        </Grid>
                            )
                        }
                    </Grid>


</Box>
);
}

export default DataSets;