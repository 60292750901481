import { Data, View } from "../models/state";

export const FETCH_STARTED = "FETCH_STARTED";
export const DATASET_FETCH_SUCCEEDED = "DATASET_FETCH_SUCCEEDED";
export const CREATE_NEW_DATASET = "CREATE_NEW_DATASET";
export const DATASET_VIEW = "DATASET_VIEW";
export const SELECTED_DATASET = "SELECTED_DATASET";


export interface FetchStartedAction {
    type: typeof FETCH_STARTED;
}

export const fetchStarted = (): FetchStartedAction => {
    return {
        type: FETCH_STARTED
    };
}

export interface DataSetsFetchSucceededAction {
    type: typeof DATASET_FETCH_SUCCEEDED;
    data: Data;
}

export interface SetViewAction {
    type: typeof DATASET_VIEW;
    view: View;
}

export interface SelectedDataSetAction {
    type: typeof SELECTED_DATASET;
    selectedDataSet?: string;
}

export const setView = (view: View): SetViewAction => {
    return {
        type: DATASET_VIEW,
        view,
    }
}

export const setSelectedDataSet = (selectedDataSet?: string): SelectedDataSetAction => {
    return {
        type: SELECTED_DATASET,
        selectedDataSet,
    }
}

export const datasetsFetchSucceeded = (data: Data): DataSetsFetchSucceededAction => { 
    return {
        type: DATASET_FETCH_SUCCEEDED,
        data,
    };
}


export type Actions = FetchStartedAction |
                      DataSetsFetchSucceededAction |
                      SetViewAction |
                      SelectedDataSetAction;