import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { createStore } from "redux";
import { Provider } from "react-redux";

import './index.css';
import App from './App';
import combineReducers, { ActionTypes } from "./reducers";
import State from './models/state';
import reportWebVitals from './reportWebVitals';
import DataSets from './components/datasets';
import Dataset from './components/dataset';
import NotFound from './components/notFound';


const store = createStore<State, ActionTypes, null, null>(combineReducers);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Provider store={store}>
        {/* <App /> */}
         <BrowserRouter>
           <Routes>
             <Route path="/" element={<App />}>
             </Route>
             <Route path="/detail" element={<App />}>
             </Route>
             <Route path="*" element={<NotFound/>}/> 
           </Routes>
         </BrowserRouter>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
