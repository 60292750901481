export interface Gridded {
            type: string;
            format: string;
            category: string;
            publisher: string | undefined | null;
            date_range?: string[];
            date_ranges: string[];
            description: string | undefined | null;
            times_generated: string[];
            time_generated?: string;
            resolutions?: number[] | string[];
            resolution?: number | string;
            lat_range: number[];
            lon_range: number[];
            native_units: string[];
            update_frequency: string;
            period: string;
            variables: string[];
            data_url?: string;
            info_url?: string;
            endpoint: string;
            api_documentation: string;
            zarr: boolean;
            tags: string[];
            names: string[];
            cids: string[];
            cid?: string;
}

export interface Station {
                type: string;
                format?: string;
                category?: string;
                variables: string[];
                publisher: string | undefined | null;
                description: string;
                time_generated: string;
                times_generated?: string[];
                update_frequency: string | undefined | null;
                period?: string | undefined | null;
                data_url?: string;
                info_url?: string;
                endpoint: string;
                lat_range?: string[];
                lon_range?: string[];
                resolution?: number;
                resolutions?: string[];
                api_documentation: string;
                zarr: boolean;
                tags: string[];
                names: string[];
                cid: string;
                cids?: string[];
}

export enum View {
    Gridded = 'Gridded',
    Station = 'Station',
}

export interface DataSets {
        [dataSetType: string]: {
            [datasetName: string]: Gridded | Station;
        };
}

export interface Data {
    datasets: DataSets;
}

export default interface State {
    loading: boolean;
    view: View;
    apiData?: Data;
    selectedDataSetType: string;
    selectedDataSet?: string;
}
