import React, { useEffect } from 'react';

import Box from '@mui/material/Box';

// import logo from './logo.svg';
import './App.css';

import Datasets from './components/datasets';
import Dataset from './components/dataset';
import data from './utils/data';
import { Container } from '@mui/system';
import DClimateLogo from './icons/dClimate-logo';
import State, { Data, View } from './models/state';
import { useDispatch, useSelector } from 'react-redux';
import { datasetsFetchSucceeded, setSelectedDataSet } from './reducers/actions';

function App() {
  const dispatch = useDispatch();

  const state = useSelector((state: State) => {
    // let apiData: Promise<Data> = data.fetch_catalog_data();
    // let selectedDataSetType: string = 'station';
    // let selectedDataSetType: string = 'gridded';
    return {
        apiData: state.apiData,
        selectedDataSetType: state.view,
        selectedDataSet: state.selectedDataSet,
    };
  });

  const showAllDataSets = () => {
    // window.location.pathname = "/catalog"
    dispatch(setSelectedDataSet(undefined))
  }

  useEffect(() => {
    data.fetch_catalog_data().then((datasets: Data) => {
        dispatch(datasetsFetchSucceeded(datasets))
    });
    
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', background: '#F5F5F5' }}>
      <Box display="flex"
           justifyContent="center"
           alignItems="center"
           sx={{my: 2}}
           >
        <DClimateLogo/>
      </Box>
      {state.selectedDataSet ? <Dataset/> : <Datasets /> }
    </Box>
  );
}

export default App;
