const InfoIcon = () => {
    return(
        // <React.Fragment>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99997 0.609375C6.04069 0.612096 4.16244 1.39162 2.77699 2.77711C1.39154 4.16248 0.612003 6.04081 0.609253 8.00009C1.01488 17.8038 14.9848 17.8004 15.3905 8.00009C15.3878 6.04081 14.6083 4.16256 13.2228 2.77711C11.8374 1.39166 9.95907 0.612125 7.99979 0.609375H7.99997ZM7.99997 14.3594C6.31394 14.3576 4.6974 13.687 3.50519 12.4947C2.31297 11.3024 1.64234 9.68602 1.6405 7.99991C1.98425 -0.435625 14.0155 -0.435625 14.3593 7.99991C14.3575 9.68593 13.6869 11.3025 12.4946 12.4947C11.3023 13.6869 9.6859 14.3575 7.99979 14.3594H7.99997Z" fill="#006C9F"/>
            <path d="M8.00013 6.82097C7.86334 6.82097 7.73222 6.87532 7.63553 6.972C7.53885 7.06868 7.4845 7.19981 7.4845 7.3366V10.5713C7.4845 10.7555 7.58275 10.9258 7.7423 11.0178C7.90186 11.11 8.09837 11.11 8.25793 11.0178C8.41748 10.9257 8.51573 10.7555 8.51573 10.5713V7.3366C8.51573 7.19982 8.46137 7.06869 8.36469 6.972C8.26801 6.87532 8.13688 6.82097 8.0001 6.82097H8.00013Z" fill="#006C9F"/>
            <path d="M7.63563 4.87865C7.61121 4.90271 7.58929 4.92921 7.57032 4.95771C7.55155 4.98593 7.53544 5.01587 7.52219 5.04709C7.51102 5.07853 7.50178 5.11068 7.49469 5.14334C7.46261 5.30641 7.51109 5.47499 7.62496 5.59608C7.73883 5.71718 7.90405 5.77591 8.06883 5.75392C8.23361 5.73194 8.37763 5.63189 8.45575 5.48523C8.53388 5.33849 8.53646 5.16311 8.46277 5.01415C8.38901 4.86519 8.24799 4.76093 8.084 4.734C7.91993 4.70715 7.75299 4.761 7.63562 4.87866L7.63563 4.87865Z" fill="#006C9F"/>
        </svg>
        
        // </React.Fragment>
    )
}

export default InfoIcon;