import React, { FC, useEffect, useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Outlet } from "react-router-dom";
import { NavigationType, UNSAFE_NavigationContext, Link as RouterLink } from "react-router-dom";
import { History,} from "history";

import { useTheme } from "@mui/material/styles";
import { ThemeProvider, createTheme, Container } from '@mui/system';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { FormControl, InputLabel, Link, MenuItem, Select, SelectChangeEvent, TextField, Typography} from "@mui/material";

import State, { Data, Gridded, Station } from '../models/state';
import data from '../utils/data';
import { datasetsFetchSucceeded, setSelectedDataSet } from "../reducers/actions";
import ZarrLogo from "../icons/zarr-logo";

const Dataset: FC<{}> = () => {
    const screenSize = window.innerWidth;
    const isMobile = screenSize <= 700;
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

    // const [apiData, setApiData] = useState('');
    const [datasetNameIndex, setDatasetNameIndex] = useState('0');

    const state = useSelector((state: State) => {
        let selectedDataSet: Gridded | Station | undefined = undefined
        if (state.selectedDataSet && state.apiData) {
            selectedDataSet = state.apiData.datasets[state.view][state.selectedDataSet] 
        }
        const view = state.view
        
        return {
            apiData: state.apiData,
            selectedDataSet,
            view,
            selectedDataSetName: state.selectedDataSet
        };
    });

    const showAllDataSets = () => {
        dispatch(setSelectedDataSet(undefined))
    }

    const handleSelectChange = (event: any) => {
        setDatasetNameIndex(event.target.value as string)
    }
    
    const path = window.location.pathname; 
    useEffect(() => {
        if (path === '/') {
            showAllDataSets()
        }
    }, [path]);

    return (
        
        <Box sx={{ display: 'flex', flexDirection: "column", mx: 'auto', width: {xs: '100%', md: 900, lg: 1400}}}>
            <RouterLink to="/" style={{ textDecoration: 'none' }}>
                <Button variant="outlined" onClick={showAllDataSets} sx={{width: 'min-content', ml:{xs:2, md:1}, mb: 3}}>Back</Button>
            </RouterLink>
           {state.selectedDataSet?.type == "Gridded" && state.selectedDataSet.names.length > 0 && 
                <Box sx={{mx: {xs:"20px", md: 0}}}>
                    <Grid container direction="row">
                        <Grid item xs={4}>
                            <label id="select-dataset-label">Select Dataset: </label>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 9}>
                            <Box className="select-dataset">
                                <Box className="select">
                                    <select name="select-dataset" id="select-dataset" onChange={handleSelectChange}>
                                        {state.selectedDataSet.names && state.selectedDataSet.names.map((name, i) => (
                                            <option key={name} value={i}>{name.split('_').join(' ').split('-').join(', ')}</option>
                                        ))}   
                                    </select>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
           }
            {state.selectedDataSet &&   
                    <Grid container direction="row" >
                        <Grid sm={12} md={6} item={true} sx={{ width: '100%'}}>
                            <Paper sx={{p: 3, display: 'flex', flexDirection: 'row', mt:"20px", mb: 0, mx: {xs:"20px", md: 0}, borderRadius: 2, boxShadow: '0px 10px 20px -4px rgba(136, 136, 136, 0.25)'}}>
                                <Container sx={{overflowWrap: "break-word"}}>
                                    <Typography sx={{fontSize: "25px", fontWeight: 550, textTransform: 'uppercase', color: "#54555B", height: 41, mb:4 }}>{state.selectedDataSetName} {state.selectedDataSet.zarr && <ZarrLogo/>}</Typography>
                                    <Grid container sx={{mb:4}}>
                                        {!isMobile && state.selectedDataSet.tags && state.selectedDataSet.tags[0] && state.selectedDataSet.tags[0] !== "" && state.selectedDataSet.tags.map((tag)=>
                                            <Box sx={{borderRadius: 100, 
                                                      border: state.view === 'Station' ? '2px solid #30A1D6' : '2px solid #0DA000',
                                                      background: state.view === 'Station' ? '##F0FAFF' : '#D2FFD7',
                                                      height: 25,
                                                      m: "4px",
                                                      textAlign: 'center',
                                                      overflowX: 'auto'
                                                        }}
                                                    key={tag}>
                                                <Typography sx={{display: 'flex', 
                                                                fledDirection: 'row',
                                                                mx:2,
                                                                // my:"2px",
                                                                color: state.view === 'Station' ? '#30A1D6' : '#0DA000', 
                                                            }}>{tag}</Typography>
                                            </Box>
                                        )} 
                                        </Grid>
                                    <h3>
                                        <Typography sx={{color: "#4B5563;"}}>
                                            Description
                                        </Typography>
                                    </h3>
                                   
                                    <Typography sx={{mb: 2, color: "#4B5563;"}} >
                                        {state.selectedDataSet.description}
                                    </Typography> 
                                   
                                    { state.view === 'Station' ?
                                        <Box sx={{mb:9}}>
                                            <Typography>
                                            {state.selectedDataSet.update_frequency && "Update Frequency"}
                                            </Typography>
                                            <Typography sx={{mb: 2, color: "#4B5563;"}} >
                                                {state.selectedDataSet.update_frequency}
                                            </Typography> 
                                            <Typography sx={{}}>
                                                {state.selectedDataSet.period && "Period"} 
                                            </Typography>
                                            <Typography sx={{mb: 2, color: "#4B5563;"}} >
                                                {state.selectedDataSet.period}
                                            </Typography> 
                                        </Box> : 
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography>Latitude Range</Typography>
                                                <Typography sx={{color: 'rgba(84, 85, 91, 0.7)', mb:2}}>
                                                    [{state.selectedDataSet.lat_range && state.selectedDataSet.lat_range[0]}, {state.selectedDataSet.lat_range && state.selectedDataSet.lat_range[1]}]
                                                </Typography>
                                                <Typography>Time Generated</Typography>
                                                <Typography sx={{color: 'rgba(84, 85, 91, 0.7)', mb: 2}}>
                                                    {state.selectedDataSet.times_generated && state.selectedDataSet.times_generated[parseInt(datasetNameIndex)]}
                                                </Typography>
                                                <Typography>Resolution</Typography>
                                                <Typography sx={{color: 'rgba(84, 85, 91, 0.7)', mb: 3}}>
                                                    {state.selectedDataSet.resolutions && state.selectedDataSet.resolutions[parseInt(datasetNameIndex)]}
                                                 </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>Longitude Range</Typography>
                                                <Typography sx={{color: 'rgba(84, 85, 91, 0.7)', mb: 2}}>
                                                    [{state.selectedDataSet.lon_range && state.selectedDataSet.lon_range[0]}, {state.selectedDataSet.lon_range && state.selectedDataSet.lon_range[1]}]
                                                </Typography>
                                                <Typography>Update Frequency</Typography>
                                                <Typography sx={{color: 'rgba(84, 85, 91, 0.7)', mb:3}}>
                                                    {state.selectedDataSet.update_frequency}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                  <Grid container sx={{mb:4}}>
                                        {isMobile && state.selectedDataSet.tags && state.selectedDataSet.tags[0] && state.selectedDataSet.tags[0] !== "" && state.selectedDataSet.tags.map((tag)=>
                                            <Box sx={{borderRadius: 100, 
                                                      border: state.view === 'Station' ? '2px solid #30A1D6' : '2px solid #0DA000',
                                                      background: state.view === 'Station' ? '##F0FAFF' : '#D2FFD7',
                                                      height: 25,
                                                      m: "4px",
                                                      textAlign: 'center',
                                                      overflowX: 'auto'
                                                        }}
                                                    key={tag}>
                                                <Typography sx={{display: 'flex', 
                                                                fledDirection: 'row',
                                                                mx:2,
                                                                // my:"2px",
                                                                color: state.view === 'Station' ? '#30A1D6' : '#0DA000', 
                                                            }}>{tag}</Typography>
                                            </Box>
                                        )} 
                                    </Grid>
                                </Container>
                            </Paper>
                        </Grid>    
                        <Grid sm={12} md={6} item={true} sx={{ width: '100%'}}>
                            <a className="dataset-page-link" target="_blank" rel="noreferrer noopener" href={state.selectedDataSet.api_documentation}>
                            <Paper sx={{py: 2, px: 2, display: 'flex', flexDirection: 'row', margin: "20px", borderRadius: 2, boxShadow: '0px 10px 20px -4px rgba(136, 136, 136, 0.25)' }}>
                                <Container sx={{overflowWrap: "break-word", mb:2}}>
                                        <Typography variant="h5" sx={{color: "rgb(48, 161, 214)", mb: 2}}>API Documentation</Typography>
                                        {state.selectedDataSet.endpoint}
                                </Container>
                            </Paper>  
                                    </a>  
                            <Paper sx={{py: 2, display: 'flex', flexDirection: 'row', margin: "20px", borderRadius: 2, boxShadow: '0px 10px 20px -4px rgba(136, 136, 136, 0.25)' }}>
                                <Container sx={{overflowWrap: "break-word"}}>
                                    <Typography sx={{mx:2}}>
                                        Published
                                    </Typography>
                                    <Typography sx={{mb:2, mx: 2, color: "#4B5563"}}>
                                    {state.selectedDataSet.times_generated && state.selectedDataSet.times_generated[parseInt(datasetNameIndex)]}
                                    </Typography>
                                    <Typography sx={{mx:2}}>
                                        Publisher
                                    </Typography>
                                    <Typography sx={{mb:2, mx: 2, color: "#4B5563"}}>
                                        {state.selectedDataSet.publisher}
                                    </Typography>
                                    <Typography sx={{mx:2}}>
                                        Type
                                    </Typography>
                                    <Typography sx={{mb:2, mx: 2, color: "#4B5563"}}>
                                        {state.selectedDataSet.type}
                                    </Typography>
                                    <Typography sx={{mx:2}}>
                                        Hash / CID
                                    </Typography>

                                    <Typography sx={{mb:2, mx: 2, color: "#4B5563"}}>
                                        {state.selectedDataSet.type === 'Station' ? state.selectedDataSet.cid : 
                                        state.selectedDataSet.cids && state.selectedDataSet.cids[parseInt(datasetNameIndex)]}
                                    </Typography>
                                    <Typography sx={{mx:2}}>
                                        Info URL
                                    </Typography>
                                    <Typography sx={{mb:2, mx: 2}}>
                                        <a className="dataset-page-link" target="_blank" rel="noreferrer noopener" href={state.selectedDataSet.info_url}>
                                            {state.selectedDataSet.info_url}
                                        </a>
                                    </Typography>
                                    <Typography sx={{mx: 2}}>
                                        Source Data URL
                                    </Typography>
                                    <Typography sx={{mx:2, mb: 2}}>
                                        <a className="dataset-page-link" target="_blank" rel="noreferrer noopener" href={state.selectedDataSet.data_url}>
                                            {state.selectedDataSet.data_url}
                                        </a>
                                    </Typography>
                                </Container>
                            </Paper>  
                        </Grid>
                    </Grid>
                }
            </Box>
    );
}

export default Dataset;