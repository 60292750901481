import {Data} from "../models/state";
// import * as dataJson from "../data/metadata.json";

class CatalogData {
    async fetch_catalog_data(): Promise<Data> {
        // const headers = new Headers()
        // headers.append('Content-Type', 'application/json');
        // headers.append("Access-Control-Allow-Origin", "*");
        const response = await fetch("https://seashell-app-v6jzl.ondigitalocean.app/get_catalog", {
            method: 'GET',
            // headers: headers,
        })
        const data = await response.json()
        return data;
    }
}

const data = new CatalogData();
export default data
